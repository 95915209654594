import * as t from "io-ts";
import { optionFromUndefined } from "../globalDomain";

export const BankerActionName = t.keyof(
  {
    AUT_ID: true,
    AUT_SK_PUSH: true,
    AUT_SK_QR: true,
    CA_OPEN: true,
    MTG_OPEN: true,
    CF_OPEN: true,
    PERSONAL_PROFILE_EDIT: true,
    SECURITY_PROFILE_EDIT: true,
    IDENTIFICATION_CLIENT_ENABLE: true,
  },
  "BankerActionName"
);

export type BankerActionName = t.TypeOf<typeof BankerActionName>;

export const BankerAction = t.type(
  {
    enabled: t.boolean,
    action: BankerActionName,
  },
  "BankerAction"
);

export type BankerAction = t.TypeOf<typeof BankerAction>;

export const BankerDetails = t.type({
  firstName: t.string,
  lastName: t.string,
  userId: t.string,
  lastLogin: optionFromUndefined(t.string),
});

export type BankerDetails = t.TypeOf<typeof BankerDetails>;
